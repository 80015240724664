<template>
  <v-container class="contenedor-main">
    <v-container v-if="periodosAlertas.length == 0">
      <v-progress-circular :width="6" :size="50" color="#0D47A1" indeterminate></v-progress-circular>
    </v-container>
    <v-container v-if="periodosAlertas.length != 0" v-bind:style="{'min-width': '100%'}">
      <v-row>
        <v-col cols="12" md="12">
          <v-card outlined elevation="0">
            <h4 class="title">Novedades en mis materias</h4>
            <v-toolbar color="transparent" flat>
              <div style="overflow-x:auto; white-space: nowrap;">
                <v-container class="menuBar" :id="itemP" v-for="itemP, index in periodosAlertas" :key="index"
                  @click="showCodes(itemP) ">
                  <v-row>
                    <v-col v-bind:style="{'padding': '8px'}">
                      <span class="textMenu">{{itemP}}</span>
                      <v-icon v-if="valores[index] != 0" dark medium right color="orange">mdi-alert-octagon</v-icon>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-toolbar>
            <v-container v-if="tableDT == true">
              <v-data-table :headers="headersAlertas" :items="itemsAlertas" no-results-text="Sin resultados"
                sort-by="Nombre" :items-per-page="itemsAlertas.length" :footer-props="{
                'items-per-page-text': 'items por página',
                'items-per-page-all-text': 'Todos', 
                'items-per-page-options' :[-1, 5,10,15]
            }" dense></v-data-table>
            </v-container>
            <v-container v-if="tableDT == false ">
              <line-chart :chart-data="novedadesG"></line-chart>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" md="2"></v-col>
      </v-row>
    </v-container>
    <v-container v-if="periodosAlertas.length != 0" v-bind:style="{'min-width': '100%'}">
      <v-row>
        <v-col cols="12" md="12">
          <v-card outlined elevation="0">
            <h4 class="title">Novedades en mis trabajos</h4>
            <v-toolbar color="transparent" flat>
              <div style="overflow-x:auto; white-space: nowrap;">
                <v-container class="menuBar" :id="itemPT" v-for="itemPT, index in periodosTAlertas" :key="index"
                  @click="showCodesT(itemPT) ">
                  <v-row>
                    <v-col v-bind:style="{'padding': '8px'}">
                      <span class="textMenu">{{itemPT}}</span>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-toolbar>
            <v-data-table :headers="headersT" :items="trabajos" :search="searchT" no-results-text="Sin resultados"
              sort-by="Nombre" dense :header-props="{'sortByText': 'Organizar por'}" :items-per-page="trabajos.length"
              :footer-props="{
                'items-per-page-text': 'items por página',
                'items-per-page-all-text': 'Todos', 
                'items-per-page-options' :[-1, 5,10,15]
            }">
              >
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="12" md="2"></v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";

export default {
  data() {
    return {
      // novedades
      periodosAlertas: [],
      periodosTAlertas: [],
      periodo: null,
      codesAlertas: [],
      code: null,
      infoAlertas: [],
      alertas: null,
      filtroM: [],
      filtroT: [],
      headersAlertas: [],
      itemsAlertas: [],
      infoTNoved: [],
      tableDT: null,
      listaMaterias: [],
      listaPeriodos: [],
      info: false,
      novedades: [],
      headers: [
        {
          text: "Nombre",
          align: "start",
          value: "nombre"
        },
        { text: "Cantidad", value: "cantidad" }
      ],
      valores: [],
      headersT: [],
      trabajos: [],
      searchT: ""
    };
  },
  mounted() {
    // this.cursosProfes()
    this.buscar();
    document.title = "Novedades";
  },
  methods: {
    hoverD(data, curso) {
      var l = Object.keys(data);
      for (var i = 0; i < l.length; i++) {
        if (l[i] == curso) {
          document.getElementById(curso).style.borderTop = "2px solid  #0D47A1";
        } else {
          document.getElementById(l[i]).style.borderTop = "none";
        }
      }
    },
    mostrar(novedades, curso, data) {
      this.hoverD(data, curso);
      this.novedades = [];
      var val = Object.values(novedades);
      var key = Object.keys(novedades);
      for (var i = 0; i < val.length; i++) {
        this.novedades.push({ nombre: key[i], cantidad: val[i] });
      }
      this.info = true;
    },
    buscar() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(idToken => {
          const fd = new FormData();
          fd.append("token", idToken);
          axios.post(host + "/novedades", fd).then(result => {
            if (result.data.text == "Error, dirección IP bloqueada") {
              this.$alert("IP bloqueada por el administrador");
              firebase.auth().signOut();
            }
            let data = result.data.info;
            let trabajos = result.data.trabajos;
            this.filtroM = data;
            this.filtroT = trabajos;
            this.warnigs(this.filtroM);
            var nov = Object.keys(data);
            var nov2 = Object.keys(trabajos);
            this.periodosAlertas = Object.values(nov);
            this.periodosTAlertas = Object.values(nov2);
          });
        })
        .catch(function(error) {
          this.$alert("Error " + error.message);
        });
    },
    showCodes(data) {
      this.codesAlertas = Object.keys(this.filtroM[data]);
      this.periodo = data;
      this.showMaterias(data);
      this.selected(this.periodosAlertas, data);
    },
    showMaterias(data) {
      // console.log(data)
      // this.selected(this.codesAlertas, data)
      this.headersAlertas = [];
      this.itemsAlertas = [];
      var valueP = Object.values(this.filtroM[data]);
      var valueM = Object.values(valueP[0]);
      var valueK = Object.keys(this.filtroM[data]);
      var valueA = Object.keys(valueM[0]);
      // los headers
      this.headersAlertas.push({ text: "CÓDIGO", value: "codigo" });
      this.headersAlertas.push({ text: "nombre", value: "nombre" });
      for (var A = 0; A < valueA.length; A++) {
        this.headersAlertas.push({ text: valueA[A], value: valueA[A] });
      }
      var extraNombre = [];
      for (var p = 0; p < valueP.length; p++) {
        var nombreM = Object.keys(valueP[p]);
        var dataM = Object.values(valueP[p]);
        for (var m = 0; m < dataM.length; m++) {
          extraNombre.push(dataM[m]);
        }
        extraNombre[p].codigo = valueK[p];
        extraNombre[p].nombre = nombreM;
      }
      this.itemsAlertas = extraNombre;
      this.tableDT = true;
    },
    showCodesT(data) {
      this.codesAlertas = Object.keys(this.filtroT[data]);
      this.periodo = data;
      this.showTrabajos(data);
      this.selected(this.periodosTAlertas, data);
    },
    showTrabajos(data) {
      this.headersT = [];
      this.trabajos = [];
      this.infoTNoved = [];
      var valueK = Object.keys(this.filtroT[data]);
      for (var A = 0; A < valueK.length; A++) {
        this.headersT.push({ text: valueK[A], value: valueK[A] });
      }
      this.trabajos = [this.filtroT[data]];
    },
    warnigs(data) {
      var v = Object.values(data);
      var va = Object.values(v);
      for (var i = 0; i < va.length; i++) {
        var sumar = 0;
        var sum = 0;
        var val = Object.values(va[i]);
        for (var j = 0; j < val.length; j++) {
          var valu = Object.values(val[j]);
          for (var k = 0; k < valu.length; k++) {
            var value = Object.values(valu[k]);
            var key = Object.keys(valu[k]);
            for (var h = 0; h < value.length; h++) {
              if (key[h] == "OK" || key[h] == "NUEVO") {
                sum = sumar + sum + value[h];
              } else {
                sumar = 0;
              }
            }
          }
        }
        this.valores.push(sum);
      }
    },
    selected(data, name) {
      var l = data;
      for (var i = 0; i < l.length; i++) {
        if (l[i] == name) {
          document.getElementById(name).style.borderTop = "4px solid #0D47A1";
        } else {
          document.getElementById(l[i]).style.borderTop = "0.5px solid #0D47A1";
        }
      }
      if (this.materia != null && this.periodo != null) {
        this.buscando = true;
        this.buscar();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  padding-top: 2%;
  font-size: 15px;
  font-weight: 300;
  margin: 1%;
  text-align: center;
}

.textMenu{
    opacity: 0.6;
    font-size:14px;
    font-weight: 500; 
    text-transform: uppercase;
    color:  rgb(22, 52, 135);
    padding: 0px;
    text-align: center;
}

.menuBar {
  width: auto;
  display: inline-block;
  border-radius: 8px 8px 0px 0px;
  border: 0.5px solid #0d47a1;
  border-bottom: none;
}

.menuBar:hover {
  color: #e8ffe9;
  opacity: 0.6;
}

</style>