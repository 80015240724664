var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"contenedor-main"},[(_vm.periodosAlertas.length == 0)?_c('v-container',[_c('v-progress-circular',{attrs:{"width":6,"size":50,"color":"#0D47A1","indeterminate":""}})],1):_vm._e(),(_vm.periodosAlertas.length != 0)?_c('v-container',{style:({'min-width': '100%'})},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"outlined":"","elevation":"0"}},[_c('h4',{staticClass:"title"},[_vm._v("Novedades en mis materias")]),_c('v-toolbar',{attrs:{"color":"transparent","flat":""}},[_c('div',{staticStyle:{"overflow-x":"auto","white-space":"nowrap"}},_vm._l((_vm.periodosAlertas),function(itemP,index){return _c('v-container',{key:index,staticClass:"menuBar",attrs:{"id":itemP},on:{"click":function($event){return _vm.showCodes(itemP)}}},[_c('v-row',[_c('v-col',{style:({'padding': '8px'})},[_c('span',{staticClass:"textMenu"},[_vm._v(_vm._s(itemP))]),(_vm.valores[index] != 0)?_c('v-icon',{attrs:{"dark":"","medium":"","right":"","color":"orange"}},[_vm._v("mdi-alert-octagon")]):_vm._e()],1)],1)],1)}),1)]),(_vm.tableDT == true)?_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headersAlertas,"items":_vm.itemsAlertas,"no-results-text":"Sin resultados","sort-by":"Nombre","items-per-page":_vm.itemsAlertas.length,"footer-props":{
              'items-per-page-text': 'items por página',
              'items-per-page-all-text': 'Todos', 
              'items-per-page-options' :[-1, 5,10,15]
          },"dense":""}})],1):_vm._e(),(_vm.tableDT == false )?_c('v-container',[_c('line-chart',{attrs:{"chart-data":_vm.novedadesG}})],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}})],1)],1):_vm._e(),(_vm.periodosAlertas.length != 0)?_c('v-container',{style:({'min-width': '100%'})},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"outlined":"","elevation":"0"}},[_c('h4',{staticClass:"title"},[_vm._v("Novedades en mis trabajos")]),_c('v-toolbar',{attrs:{"color":"transparent","flat":""}},[_c('div',{staticStyle:{"overflow-x":"auto","white-space":"nowrap"}},_vm._l((_vm.periodosTAlertas),function(itemPT,index){return _c('v-container',{key:index,staticClass:"menuBar",attrs:{"id":itemPT},on:{"click":function($event){return _vm.showCodesT(itemPT)}}},[_c('v-row',[_c('v-col',{style:({'padding': '8px'})},[_c('span',{staticClass:"textMenu"},[_vm._v(_vm._s(itemPT))])])],1)],1)}),1)]),_c('v-data-table',{attrs:{"headers":_vm.headersT,"items":_vm.trabajos,"search":_vm.searchT,"no-results-text":"Sin resultados","sort-by":"Nombre","dense":"","header-props":{'sortByText': 'Organizar por'},"items-per-page":_vm.trabajos.length,"footer-props":{
              'items-per-page-text': 'items por página',
              'items-per-page-all-text': 'Todos', 
              'items-per-page-options' :[-1, 5,10,15]
          }}},[_vm._v(" > ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }